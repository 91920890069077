// Your variable overrides
$body-bg: white;
$body-color: #777880;
$blue: #007bff;
$indigo: #6610f2;
$purple: #63546b;
$pink: #e83e8c;
$red: #ee1c23;
$orange: #fd7e14;
$yellow: #e0ca3c;
$green: #00bf38;
$teal: #20c997;
$cyan: #17a2b8;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 4;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.05;
$font-family-base: 'Roboto Regular',
sans-serif;
$link-color: $red;
$link-hover-color: darken($red, 15%);
$table-cell-padding: .95rem !default;
$card-border-radius: 0;
$pagination-padding-y: .6rem;
// $pagination-padding-x : 0;
$pagination-color: $body-color;
$pagination-bg: #f4f2f4;
$pagination-border-color: $green;
$pagination-hover-color: $green;
$pagination-hover-bg: white;
$pagination-hover-border-color: $green;
$pagination-active-color: white;
$pagination-active-bg: $green;
$pagination-active-border-color: $green;
$enable-rounded: false;
$input-bg: white;
$input-border-color: #eeefef;
$input-btn-padding-y: 0.7rem;
$input-btn-padding-x: 2rem;
// Bootstrap and its default variables
// @import "../../node_modules/bootstrap/scss/../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/_functions";
@import "../../node_modules/bootstrap/scss/_variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/_root";
@import "../../node_modules/bootstrap/scss/_print";
@import "../../node_modules/bootstrap/scss/_reboot";
@import "../../node_modules/bootstrap/scss/_type";
@import "../../node_modules/bootstrap/scss/_images";
@import "../../node_modules/bootstrap/scss/_code";
@import "../../node_modules/bootstrap/scss/_grid";
@import "../../node_modules/bootstrap/scss/_tables";
@import "_forms";
@import "_buttons";
@import "../../node_modules/bootstrap/scss/_transitions";
@import "../../node_modules/bootstrap/scss/_dropdown";
@import "../../node_modules/bootstrap/scss/_button-group";
@import "../../node_modules/bootstrap/scss/_input-group";
@import "../../node_modules/bootstrap/scss/_custom-forms";
@import "../../node_modules/bootstrap/scss/_nav";
@import "../../node_modules/bootstrap/scss/_navbar";
@import "_card";
@import "../../node_modules/bootstrap/scss/_breadcrumb";
@import "_pagination";
@import "../../node_modules/bootstrap/scss/_badge";
@import "../../node_modules/bootstrap/scss/_jumbotron";
@import "../../node_modules/bootstrap/scss/_alert";
@import "../../node_modules/bootstrap/scss/_progress";
@import "../../node_modules/bootstrap/scss/_media";
@import "../../node_modules/bootstrap/scss/_list-group";
@import "../../node_modules/bootstrap/scss/_close";
@import "../../node_modules/bootstrap/scss/_modal";
@import "../../node_modules/bootstrap/scss/_tooltip";
@import "../../node_modules/bootstrap/scss/_popover";
@import "../../node_modules/bootstrap/scss/_carousel";
@import "../../node_modules/bootstrap/scss/_utilities";