// stylelint-disable selector-no-qualifying-type
//
// Textual form controls
//
.form-control {
    display: block;
    width: 100%;
    padding: $input-btn-padding-y $input-btn-padding-x;
    font-size: $font-size-base;
    line-height: $input-btn-line-height;
    color: $input-color;
    background-color: $input-bg; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214.
    background-image: none;
    background-clip: padding-box;
    border: $input-btn-border-width solid $input-border-color; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @if $enable-rounded {
        // Manually use the if/else instead of the mixin to account for iOS override
        border-radius: $input-border-radius;
    }
    @else {
        // Otherwise undo the iOS default
        border-radius: 0;
    }
    @include box-shadow($input-box-shadow);
    @include transition($input-transition); // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
        background-color: transparent;
        border: 0;
    } // Customize the `:focus` state to imitate native WebKit styles.
    @include form-control-focus(); // Placeholder
    &::placeholder {
        color: $input-placeholder-color; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
    } // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
        background-color: $input-disabled-bg; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
        opacity: 1;
    }
}

select.form-control {
    &:not([size]):not([multiple]) {
        height: $input-height;
    }
    &:focus::-ms-value {
        // Suppress the nested default white text on blue background highlight given to
        // the selected option text when the (still closed) <select> receives focus
        // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
        // match the appearance of the native widget.
        // See https://github.com/twbs/bootstrap/issues/19398.
        color: $input-color;
        background-color: $input-bg;
    }
} // Make file inputs better match text inputs by forcing them to new lines.
.form-control-file,
.form-control-range {
    display: block;
} //
// Labels
//
// For use with horizontal and inline forms, when you need the label text to
// align with the form controls.
.col-form-label {
    padding-top: calc(#{$input-btn-padding-y} + #{$input-btn-border-width});
    padding-bottom: calc(#{$input-btn-padding-y} + #{$input-btn-border-width});
    margin-bottom: 0; // Override the `<label>` default
    line-height: $input-btn-line-height;
}

.col-form-label-lg {
    padding-top: calc(#{$input-btn-padding-y-lg} + #{$input-btn-border-width});
    padding-bottom: calc(#{$input-btn-padding-y-lg} + #{$input-btn-border-width});
    font-size: $font-size-lg;
    line-height: $input-btn-line-height-lg;
}

.col-form-label-sm {
    padding-top: calc(#{$input-btn-padding-y-sm} + #{$input-btn-border-width});
    padding-bottom: calc(#{$input-btn-padding-y-sm} + #{$input-btn-border-width});
    font-size: $font-size-sm;
    line-height: $input-btn-line-height-sm;
} //
// Legends
//
// For use with horizontal and inline forms, when you need the legend text to
// be the same size as regular labels, and to align with the form controls.
.col-form-legend {
    padding-top: $input-btn-padding-y;
    padding-bottom: $input-btn-padding-y;
    margin-bottom: 0;
    font-size: $font-size-base;
} // Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)
.form-control-plaintext {
    padding-top: $input-btn-padding-y;
    padding-bottom: $input-btn-padding-y;
    margin-bottom: 0; // match inputs if this class comes on inputs with default margins
    line-height: $input-btn-line-height;
    background-color: transparent;
    border: solid transparent;
    border-width: $input-btn-border-width 0;
    &.form-control-sm,
    &.form-control-lg {
        padding-right: 0;
        padding-left: 0;
    }
} // Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// The `.form-group-* form-control` variations are sadly duplicated to avoid the
// issue documented in https://github.com/twbs/bootstrap/issues/15074.
.form-control-sm {
    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
    font-size: $font-size-sm;
    line-height: $input-btn-line-height-sm;
    @include border-radius($input-border-radius-sm);
}

select.form-control-sm {
    &:not([size]):not([multiple]) {
        height: $input-height-sm;
    }
}

.form-control-lg {
    padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
    font-size: $font-size-lg;
    line-height: $input-btn-line-height-lg;
    @include border-radius($input-border-radius-lg);
}

select.form-control-lg {
    &:not([size]):not([multiple]) {
        height: $input-height-lg;
    }
} // Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.
.form-group {
    margin-bottom: $form-group-margin-bottom;
    label {
        font-size: 12px;
        margin-left: 10px; 
        margin-right: 10px;
        color: $body-color;
        text-transform: uppercase;
        font-weight: 500; 
    }
    small {
        &.error {
            color: $red;
        }
    }
}

.form-text {
    display: block;
    margin-top: $form-text-margin-top;
    &.error {
        color: $red;
    }
} // Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.
.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
    >.col,
    >[class*="col-"] {
        padding-right: 5px;
        padding-left: 5px;
    }
} // Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.
.form-check {
    position: relative;
    display: block;
    margin-bottom: $form-check-margin-bottom;
    &.disabled {
        .form-check-label {
            color: $text-muted;
        }
    }
}

.form-check-label {
    padding-left: $form-check-input-gutter;
    margin-bottom: 0; // Override default `<label>` bottom margin
}

.form-check-input {
    position: absolute;
    margin-top: $form-check-input-margin-y;
    margin-left: -$form-check-input-gutter;
} // Radios and checkboxes on same line
.form-check-inline {
    display: inline-block;
    margin-right: $form-check-inline-margin-x;
    .form-check-label {
        vertical-align: middle;
    }
} // Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.
@include form-validation-state("valid", $form-feedback-valid-color);
@include form-validation-state("invalid", $form-feedback-invalid-color); // Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).
.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)
    // Because we use flex, the initial sizing of checkboxes is collapsed and
    // doesn't occupy the full-width (which is what we want for xs grid tier),
    // so we force that here.
    .form-check {
        width: 100%;
    } // Kick in the inline
    @include media-breakpoint-up(sm) {
        label {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
        } // Inline-block all the things for "inline"
        .form-group {
            display: flex;
            flex: 0 0 auto;
            flex-flow: row wrap;
            align-items: center;
            margin-bottom: 0;
        } // Allow folks to *not* use `.form-group`
        .form-control {
            display: inline-block;
            width: auto; // Prevent labels from stacking above inputs in `.form-group`
            vertical-align: middle;
        } // Make static controls behave like regular ones
        .form-control-plaintext {
            display: inline-block;
        }
        .input-group {
            width: auto;
        } // Remove default margin on radios/checkboxes that were used for stacking, and
        // then undo the floating of radios and checkboxes to match.
        .form-check {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            margin-top: 0;
            margin-bottom: 0;
        }
        .form-check-label {
            padding-left: 0;
        }
        .form-check-input {
            position: relative;
            margin-top: 0;
            margin-right: $form-check-input-margin-x;
            margin-left: 0;
        } // Custom form controls
        .custom-control {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 0;
        }
        .custom-control-indicator {
            position: static;
            display: inline-block;
            margin-right: $form-check-input-margin-x; // Flexbox alignment means we lose our HTML space here, so we compensate.
            vertical-align: text-bottom;
        } // Re-override the feedback icon.
        .has-feedback .form-control-feedback {
            top: 0;
        }
    }
}