.pagination {
    display: flex;
    @include list-unstyled();
    @include border-radius();
    justify-content: center;
  }
  
  .page-item {
    margin: 0 10px;
    &:first-child {
      .page-link {
        margin-left: 0;
        // @include border-left-radius($border-radius);
      }
    }
    &:last-child {
      .page-link {
        // @include border-right-radius($border-radius);
      }
    }
  
    &.active .page-link {
      z-index: 2;
      color: $pagination-active-color;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border-color;
    }
  
    &.disabled .page-link {
      color: $pagination-disabled-color;
      pointer-events: none;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border-color;
    }
  }
  
  .page-link {
    width:40px;
    text-align: center;
    position: relative;
    display: block;
    padding: $pagination-padding-y $pagination-padding-x;
    margin-left: -$pagination-border-width;
    line-height: $pagination-line-height;
    color: $pagination-color;
    background-color: $pagination-bg;
    border: $pagination-border-width solid $pagination-border-color;
    span {
      padding: 2px 0;
      display: block;
    }
    @include hover-focus {
      color: $pagination-hover-color;
      text-decoration: none;
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border-color;
    }
  }
  
  
  //
  // Sizing
  //
  
  .pagination-lg {
    @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
  }
  
  .pagination-sm {
    @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
  }
  