// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
    display: inline-block;
    font-weight: $btn-font-weight;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    border: $input-btn-border-width solid transparent;
    @include button-size($input-btn-padding-y, $input-btn-padding-x, $font-size-base, $input-btn-line-height, $btn-border-radius);
    @include transition($btn-transition);
  
    // Share hover and focus styles
    @include hover-focus {
      text-decoration: none;
    }
    &:focus,
    &.focus {
      outline: 0;
      box-shadow: $input-btn-focus-box-shadow;
    }
  
    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      opacity: .65;
      @include box-shadow(none);
    }
  
    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
      background-image: none;
      @include box-shadow($input-btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
  
  // Future-proof disabling of clicks on `<a>` elements
  a.btn.disabled,
  fieldset[disabled] a.btn {
    pointer-events: none;
  }
  
  
  //
  // Alternate buttons
  //
  
  @each $color, $value in $theme-colors {
    .btn-#{$color} {
      @include button-variant($value, $value);
      @include hover {
        color: $value;
        background-color: transparent;
        border-color: $value;
      }
    }
  }
  
  @each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
      @if $color == "light" {
        @include button-outline-variant($value, $gray-900);
      } @else {
        @include button-outline-variant($value, $white);
      }
    }
  }
  
  
  //
  // Link buttons
  //
  
  // Make a button look and behave like a link
  .btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;
  
    @include hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
      background-color: transparent;
      border-color: transparent;
    }
  
    &:focus,
    &.focus {
      border-color: transparent;
      box-shadow: none;
    }
  
    &:disabled,
    &.disabled {
      color: $btn-link-disabled-color;
    }
  
    // No need for an active state here
  }
  
  
  //
  // Button Sizes
  //
  
  .btn-lg {
    @include button-size($input-btn-padding-y-lg, $input-btn-padding-x-lg, $font-size-lg, $input-btn-line-height-lg, $btn-border-radius-lg);
  }
  
  .btn-sm {
    @include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $input-btn-line-height-sm, $btn-border-radius-sm);
  }
  
  
  //
  // Block button
  //
  
  .btn-block {
    display: block;
    width: 100%;
  }
  
  // Vertically space out multiple block buttons
  .btn-block + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
  
  // Specificity overrides
  input[type="submit"],
  input[type="reset"],
  input[type="button"] {
    &.btn-block {
      width: 100%;
    }
  }
  
  .btn-raduis {
    border-radius: 35px;
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }